import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import weworkHero from '../images/weworkHero.jpg'
import weworkMobile from '../images/weworkMobile.png'
import weworkImages from '../images/wework_images.png'
import wework_main1 from '../images/wework_main1.jpg'
import wework_main2 from '../images/wework_main2.jpg'
import SEO from '../components/seo'
import Link from "../components/Link";

export default function wework() {
    return (
        <StyleWrapper>
            <Layout>
                <SEO title="WeWork Case Study" description="WeWork India partnered with 1st Main to introduce new products and alternate business models
                    to start addressing remote work culture and the growing need for flexible workspaces."
                     pageImage={weworkHero}
                     path="/wework"/>
                <div className="case__study">
                    <div className="case__study__main">

                        <div className="container">
                            <div className="case__study__herotext text-left">
                                <h5>CASE STUDY</h5>
                                <hr className="case__study__line"/>
                                <div className="case__study__title">
                                    <h5>WEWORK</h5>
                                    <h6 className="case__study__page__title d-sm-block d-none">
                                        A timely pivot in the middle of a pandemic
                                    </h6>
                                </div>
                            </div>

                            <div className="row wework__mainImg d-flex justify-content-center">
                                <div className="wework__web col-12">
                                    <img src={weworkHero} className="hero-image-desktop" alt="wework hero image"/>
                                    <img src={weworkMobile} className="hero-image-mobile"
                                         alt="wework hero image mobile"/>
                                </div>
                                {/*<div className="wework__mobile col-12">*/}
                                {/*</div>*/}
                            </div>
                            <div className="case__study__page__titleMobile d-sm-none d-block pb-4">
                                A timely pivot in the middle of a pandemic
                            </div>

                            <div className="row case__study__details">
                                <div className="col-sm-8 col-12">
                                    <p className="info__desc">As the world braced for a global pandemic, businesses began to adapt and
                                        evolve to meet customer needs. WeWork India partnered with 1st Main to
                                        introduce new products and alternate business models to start addressing
                                        remote work culture and the growing need for flexible workspaces. The Ondemand
                                        platform and Virtual Office were two such products.
                                    </p>
                                    <div className="case__study__website__links">
                                        <Link href="https://ondemand.wework.co.in/" target="_blank">VIEW WEWORK ON
                                            DEMAND</Link>
                                        <Link href="https://virtualoffice.wework.co.in/" target="_blank">VIEW WEWORK VIRTUAL
                                            OFFICE</Link>
                                    </div>
                                </div>
                                <div className="case__study__tools col-sm-4 col-12">
                                <span>
                                   <h4>What We Did:</h4>
                                   <h5>Product Management, UX, Visual Design & Development</h5>
                                </span>
                                    <span>
                                   <h4>Built With:</h4>
                                   <h5>Shopify, React JS, Node JS, Google Cloud Platform</h5>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wework__workspace">
                        <h4>Workspaces on demand</h4>
                        <p className="info__desc my-4">We partnered with WeWork to redesign workspace booking for
                            pandemic and post pandemic work in India. With individuals
                            and businesses going remote, there was a need to support
                            workers as they struggled to produce their best work while
                            being away from the office. WeWork’s On Demand platform very
                            quickly offered workspaces on demand with additional services
                            like conference room bookings and more. </p>
                        <h4>Idea <span>&#10230;</span>&nbsp;MVP in 3 weeks&nbsp;
                            <span>&#10230;</span>&nbsp;Continuous Enhancements</h4>
                    </div>
                    <div className="wework__infoImg">
                        <img src={weworkImages} alt=""/>
                    </div>
                    <div className="wework__virtual">
                        <h4>Virtual Office</h4>
                        <p className="info__desc my-4">Virtual offices help individuals and businesses get a physical
                            address without the overhead costs of leasing a physical space. Employees get to focus on
                            their
                            product or service and work from any WeWork location convenient to them. The business needs
                            of company registration address and mail forwarding are taked care of. </p>
                    </div>
                    <div className="wework__imgs container-fluid">
                            <div className="row">
                                <div className="wework__img1 col-md-6 col-12">
                                    <img src={wework_main1} alt=""/>
                                </div>
                                <div className="wework__img2 col-md-6 col-12">
                                    <img src={wework_main2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </StyleWrapper>
    )
}
const StyleWrapper = styled.div`

  .case__study__main {
    background-image: linear-gradient(rgba(227, 215, 196, 1), rgba(227, 227, 227, 1));
  }

  .wework__workspace, .wework__virtual {
    padding: 100px 20px 100px 20px;
    max-width: 900px;
    width: 100%;
  }

  .wework__imgs, .wework__infoImg {
    padding: 100px 20px 100px 20px;
    background: rgba(244, 242, 239, 1);
  }

  .wework__img1, .wework__img2 {
    display: flex;
    justify-content: center;
  }

  .wework__img1 > img, .wework__img2 > img {
    width: 100%;
    object-fit: cover;
    padding-bottom: 20px;
  }

  .hero-image-desktop {
    width: 100%;
    object-fit: cover;
  }

  img.hero-image-mobile {
    position: absolute;
    right: 0;
    top: 30px;
    max-height: 200px;
    object-fit: contain;
  }

  .wework__infoImg > img {
    object-fit: cover;
    width: 100%;
  }

  .wework__mainImg {
    overflow: hidden;
    margin: 50px 0;
    padding-bottom: 50px;
  }


  @media (min-width: 768px) {

    img.hero-image-mobile {
      right: 0;
      top: 10px;
      max-height: 450px;
    }

  }


  @media (min-width: 992px) {
    .wework {
      transition: 750ms;
      height: 100%;
    }

    .wework__main {
      padding: 150px 100px 100px 100px;
      background-image: linear-gradient(rgba(227, 215, 196, 1), rgba(227, 227, 227, 1));
    }

    .wework__workspace, .wework__virtual {
      padding: 150px 100px 100px 100px;
    }

    .wework__imgs, .wework__infoImg {
      padding: 150px 100px 200px 100px;
      background: rgba(244, 242, 239, 1);
    }

    .wework__imgs {
      margin: 0px;
    }

    .wework__img1 > img, .wework__img2 > img {
      width: 650px;
      object-fit: cover;
    }

    .wework__img2 {
      position: relative;
      top: 80px;
      left: -50px;
    }

    .hero-image-desktop {
      width: 950px;
      object-fit: cover;
    }

    .wework__mainImg {
      padding-top: 100px;
    }

    .wework__infoImg > img {
      object-fit: cover;
      width: 100%;
    }

    .wework__line {
      width: 300px;
      border: 1px solid black;
    }

    img.hero-image-mobile {
      right: 0;
      top: 100px;
      max-height: 550px;
    }

  }
`